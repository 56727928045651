import { SerializedStyles } from '@emotion/react';
import Link from 'next/link';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import {
  HorizontalScrollContainer,
  HorizontalScrollContainerController,
} from '@/components/common/HorizontalScrollContainer';
import { usePageContext } from '@/components/common/PageContext';
import { RIDITheme } from '@/components/styles/themes';
import { currentNavigationRouteSelector } from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { useNavigationScrollIntoView } from '@/hooks/useNavigationScrollIntoView';
import { useNavigationScrollPosition } from '@/hooks/useNavigationScrollPosition';
import { useOnScrollRightEnd } from '@/hooks/useOnScrollRightEnd';
import { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';

import * as styles from './GenreHomeTab.styles';

const GenreHomeSubTabItems = ({ items, itemCss, selectedId, target, className }: GenreHomeTabItemsProps) => {
  const pageContext = usePageContext();
  const [hideGradient, setHideGradient] = useState(true);
  const scrollableRef = useOnScrollRightEnd<HTMLUListElement>({ onScrollRightEnd: setHideGradient });
  const isReadyToBeVisible = useNavigationScrollPosition(scrollableRef);
  const [isManuallySelected, setIsManuallySelected] = useState(false);
  const getRef = useNavigationScrollIntoView(selectedId, items, isManuallySelected);

  return (
    <div css={styles.wrapperStyle2}>
      <ul className={className} css={[styles.defaultItemsStyle]} ref={scrollableRef} aria-hidden={!isReadyToBeVisible}>
        {items.map((item, index) => {
          const eventParams = { ...pageContext.params, path: item.path, title: item.title, index };
          return (
            <TrackViewEvent
              key={`navigation-${item.id}`}
              screenName={pageContext.screenName}
              target={target}
              params={eventParams}>
              <TrackClickEvent screenName={pageContext.screenName} target={target} params={eventParams}>
                <li css={styles.defaultItemContainer2Style} ref={getRef(item.id)}>
                  <Link
                    aria-selected={item.id === selectedId}
                    css={itemCss}
                    href={item.path}
                    key={item.id}
                    onClick={() => setIsManuallySelected(true)}>
                    {item.title}
                  </Link>
                </li>
              </TrackClickEvent>
            </TrackViewEvent>
          );
        })}
      </ul>
      <div aria-hidden={hideGradient} css={styles.buttonsBgStyles} />
    </div>
  );
};

interface GenreHomeTabItemsProps {
  items: Navigation[];
  itemCss?: (theme: RIDITheme) => SerializedStyles;
  selectedId: number;
  target: string;
  className?: string;
  children?: ReactNode;
}

const GenreHomeTabItems = ({
  items,
  itemCss,
  selectedId,
  target,
  className,
  children,
}: GenreHomeTabItemsProps): ReactJSX.Element => {
  const pageContext = usePageContext();
  const containerControllerRef = useRef<HorizontalScrollContainerController>(null);
  const childrenRefs = useRef<Record<number, HTMLElement>>({});
  const setChildRef = (itemId: number) => (element: HTMLElement | null) => {
    if (!element) {
      delete childrenRefs.current[itemId];
      return;
    }

    childrenRefs.current[itemId] = element;
  };

  useEffect(() => {
    if (!containerControllerRef.current) {
      return;
    }

    const activeCategoryElement = childrenRefs.current[selectedId ?? 0];
    if (!activeCategoryElement) {
      return;
    }

    containerControllerRef.current.focus(activeCategoryElement);
  }, [selectedId]);

  return (
    <HorizontalScrollContainer contentCss={styles.scrollWrapperStyle} ref={containerControllerRef}>
      <ul className={className} css={styles.defaultItemsStyle}>
        {items.map((item, index) => {
          const eventParams = { ...pageContext.params, path: item.path, title: item.title, index };
          return (
            <TrackViewEvent
              key={`navigation-${item.id}`}
              screenName={pageContext.screenName}
              target={target}
              params={eventParams}
              ref={setChildRef(item.id)}>
              <TrackClickEvent screenName={pageContext.screenName} target={target} params={eventParams}>
                <li css={styles.defaultItemContainer2Style}>
                  <Link aria-selected={item.id === selectedId} css={itemCss} href={item.path} key={item.id}>
                    {item.title}
                  </Link>
                </li>
              </TrackClickEvent>
            </TrackViewEvent>
          );
        })}
        {children}
      </ul>
    </HorizontalScrollContainer>
  );
};

interface GenreHomeTabProps {
  className?: string;
  children?: ReactNode;
}

export const GenreHomeTab = ({ className, children }: GenreHomeTabProps): ReactJSX.Element => {
  const currentNavigationRoute = useSelector(currentNavigationRouteSelector);

  // prettier-ignore
  const [/* selectedRootNavigation */, selectedGlobalNavigation, selectedTabItem, selectedSubTabItem] =
    currentNavigationRoute ?? [];

  const tabItems = selectedGlobalNavigation?.children;
  const subTabItems = selectedTabItem?.children;

  const hasMultipleTabItems = tabItems?.length > 1;
  const doesTabItemsExist = tabItems && selectedTabItem;
  const doesSubTabItemsExist = subTabItems && selectedSubTabItem;

  return (
    <div css={styles.wrapperStyle}>
      <div css={styles.tabWrapperStyle} className={className}>
        <div css={styles.subTabContainerStyle}>
          {hasMultipleTabItems
            ? // 웹소설
              doesTabItemsExist && (
                // 로맨스/로판/판타지/BL
                <GenreHomeTabItems
                  css={styles.webNovel2DepthStyle}
                  items={tabItems}
                  itemCss={styles.webNovel2DepthItemStyle}
                  selectedId={selectedTabItem.id}
                  target="tab"
                />
              )
            : // 웹툰, 만화, 도서
              doesSubTabItemsExist && (
                <GenreHomeSubTabItems
                  css={[styles.subTab2Style, styles.subTab6Style]}
                  items={subTabItems}
                  itemCss={styles.webNovel3DepthItemStyle}
                  selectedId={selectedSubTabItem.id}
                  target="subtab"
                />
              )}
        </div>
        {hasMultipleTabItems && doesSubTabItemsExist && (
          // 웹소설 - 웹소설/e북
          <GenreHomeTabItems
            css={styles.webNovel3DepthStyle}
            items={subTabItems}
            itemCss={styles.webNovel3DepthItemStyle}
            selectedId={selectedSubTabItem.id}
            target="subtab"
          />
        )}
        {children}
      </div>
    </div>
  );
};
